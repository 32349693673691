<template>
  <BaseModal ref="modal" title="Funnelinstellingen" max-width="tw-max-w-2xl">
    <FormulateForm
      name="funnelSettingsForm"
    >
      <div class="tw-my-2">
        <FormulateInput
          v-model="showPictures"
          type="checkbox"
          name="is_vat_liable"
          label="Toon pandafbeelding bij leads"
          outer-class="tw-my-2"
        />
        <FormulateInput
          v-model="followerLeads"
          type="select"
          name="follower_leads"
          label="Gevolgde leads"
          :options="[
            { value: 'M', label: 'Enkel mijn leads' },
            { value: 'V', label: 'Enkel gevolgde leads' }
          ]"
          outer-class="tw-my-2"
        />
        <FormulateInput
          v-model="officeObj"
          type="autocomplete"
          auto-complete-type="office"
          name="office"
          label="Kantoor"
          placeholder="Selecteer een kantoor"
          outer-class="tw-my-2"
        />
        <FormulateInput
          v-model="collaboratorObj"
          type="autocomplete"
          auto-complete-type="collaborator"
          :params="{ include_out_of_service: 1 }"
          name="collaborator"
          label="Medewerker"
          placeholder="Selecteer een medewerker"
          outer-class="tw-my-2"
        />
      </div>
      <FormulateErrors />
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'FunnelSettingsModal',
  data () {
    return {
      values: {},
      invalidPerm: false
    }
  },
  computed: {
    ...mapGetters('account', ['collaborator']),
    ...mapState('sales', ['showPictures', 'filterOffice', 'filterCollaborator']),

    togglePictures: {
      get () {
        return this.showPictures
      },
      set (value) {
        this.showFunnelPictures(value)
      }
    },
    officeObj: {
      get () {
        return this.filterOffice
      },
      set (officeObj) {
        let permission = true
        this.invalidPerm = false

        if (officeObj) {
          const { reference } = officeObj
          permission = Boolean(
            this.collaborator.hasPerm('VIEW_ALL_FUNNELS') ||
            this.collaborator.hasPerm('VIEW_FUNNEL', reference)
          )
        }

        // permisson is true by default, it gets false only when the collaborator is not permitted
        if (permission) this.setFunnelFilterOffice(officeObj)
        else this.invalidPerm = true
      }
    },
    collaboratorObj: {
      get () {
        return this.filterCollaborator
      },
      set (collaboratorObj) {
        let permission = true
        this.invalidPerm = false

        if (collaboratorObj) {
          permission = Boolean(
            this.collaborator.hasPerm('VIEW_ALL_FUNNELS') ||
            this.collaborator.hasPerm('VIEW_FUNNEL', collaboratorObj.main_office_reference)
          )
        }
        // permisson is true by default, it gets false only when the collaborator is not permitted
        if (permission) this.setFunnelFilterCollaborator(collaboratorObj)
        else this.invalidPerm = true
      }
    },
    followerLeads: {
      get: function () {
        return this.$store.state.sales.followerLeads
      },
      set: function (newValue) {
        this.$store.commit('sales/setFollowerLeads', newValue)
      }
    }
  },
  methods: {
    ...mapMutations('sales', ['showFunnelPictures', 'setFunnelFilterOffice', 'setFunnelFilterCollaborator']),

    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    }
  }
}
</script>

<style scoped>
  .picker {
    position: relative;
    top: 12px;
  }
</style>
